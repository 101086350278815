import React from "react";
import Img from "gatsby-image";

function EventCard({ event, renderFeatured }) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #000;
        width: 326px;
      `}
    >
      {event.image && (
        <Img
          css={`
            max-width: 326px;
            height: 326px;
            cursor: pointer;
          `}
          fixed={event.image.fixed}
        />
      )}
      {renderFeatured && renderFeatured()}
      <div
        css={`
          margin: 24px 0;
        `}
      >
        <div
          css={`
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 1.57;
          `}
        >
          {event.type} {event.date && ` | ${event.date}`}
        </div>
        <div
          css={`
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            margin-bottom: 4px;
          `}
        >
          {event.name}
        </div>
        {event.program && (
          <a
            href={event.program.file.url}
            download={event.name}
            target="_blank"
            rel="noreferrer"
          >
            Πρόγραμμα Παράστασης
          </a>
        )}
      </div>
    </div>
  );
}

export default EventCard;
