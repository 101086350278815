import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import { H1, H3, P } from "../components/common/Typography";
import EventCard from "../components/common/EventCard";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { MEDIA_QUERIES } from "../config";
import SEO from "../components/common/SEO";

function SummerDancingPage({ data }) {
  const events = data.allContentfulSummerDancing.nodes;

  return (
    <Layout>
      <SEO title="Summer Dancing" />
      <main>
        <div
          css={`
            text-align: center;

            ${H1} {
              margin-bottom: 32px;
              margin-top: 64px;
            }

            ${H3} {
              margin-bottom: 32px;
            }

            ${P} {
              margin-left: auto;
              margin-right: auto;
              max-width: 587px;

              &:last-child {
                margin-bottom: 72px;
              }
            }

            ${MEDIA_QUERIES.mobile} {
              ${H1} {
                margin-top: 0;
              }

              ${H3} {
                margin-bottom: 32px;
              }

              ${P} {
                padding: 0 16px;
              }
            }
          `}
        >
          <H1>Summer Dancing</H1>
          <H3>Πρόγραμμα</H3>
          <P>
            Η ΘΟΔΩΡΗΣ ΠΑΝΑΣ / ΣΧΟΛΗ ΧΟΡΟΥ ΑΘΗΝΩΝ και οι έμπειροι δάσκαλοί της
            έχουν σχεδιάσει μια καλοκαιρινή ενότητα για μικρούς και μεγάλους με
            διαδραστικά προγράμματα καλλιτεχνικής εκπαίδευσης, δημιουργικών
            δραστηριοτήτων και παιχνιδιών με κεντρικό άξονα το χορό.
          </P>
          <P>
            Τέσσερις εβδομάδες πρωϊνής απασχόλησης, μάθησης, δημιουργικότητας
            και παιχνιδιού για παιδιά από 4 - 12 ετών. Θα ασχοληθούν με
            εικαστικά, χειροτεχνίες, θεατρικό παιχνίδι, προβολές και βέβαια…
            πολύ-πολύ χορό κάθε είδους!
          </P>
          <P>
            Στόχος μας είναι, μέσα από αυτές τις δραστηριότητες, τα παιδιά μας
            να ψυχαγωγηθούν, να εκφραστούν ελεύθερα και χαρούμενα, να
            διασκεδάσουν και να γνωρίσουν από κοντά το μαγικό κόσμο της τέχνης.
          </P>
          <P>
            Τα μαθήματα είναι εβδομαδιαία, ενώ υπάρχει και η δυνατότητα πλήρους
            ή μερικής απασχόλησης, ανάλογα με τις ανάγκες των γονέων.
          </P>
          <P>
            Η σχολή προσφέρει το δεκατιανό των παιδιών με φυσικούς χυμούς,
            φρέσκα φρούτα και σνακ στο Café-bar της σχολής.
          </P>
          <P>
            Για τους έφηβους και τους ενήλικες, η σχολή μας έχει σχεδιάσει έναν
            εντατικό κύκλο καθημερινών, απογευματινών μαθημάτων SUMMERDANCING με
            Οpen Balle, Σύγχρονο, Funky Jazz, Musical / Dance, Υoga κ.ά.
          </P>
        </div>
        <SimpleReactLightbox>
          <SRLWrapper>
            <Section
              css={`
                display: grid;
                grid-template-columns: repeat(auto-fit, 326px);
                gap: 80px 23px;
                margin-bottom: 144px;

                ${MEDIA_QUERIES.mobile} {
                  justify-items: center;
                  margin-bottom: 80px;
                  grid-template-columns: 1fr;
                }
              `}
            >
              {events.map(event => (
                <EventCard
                  key={event.contentful_id}
                  event={{ ...event, type: "Εκδήλωση" }}
                />
              ))}
            </Section>
          </SRLWrapper>
        </SimpleReactLightbox>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllSummerDancing {
    allContentfulSummerDancing(sort: { fields: createdAt, order: DESC }) {
      nodes {
        contentful_id
        name
        image {
          contentful_id
          fixed(width: 652) {
            width
            src
          }
        }
      }
    }
  }
`;

export default SummerDancingPage;
